<template>
    <Combobox
        id="countryCodeCombobox"
        v-model="countryCode"
        as="div"
        :nullable="false"
        class="flex max-w-40 flex-[0_0_auto] items-center justify-center overflow-hidden"
    >
        <AFloat portal>
            <ComboboxButton
                id="countryCodeComboboxButton"
                as="div"
                class="w-fit overflow-hidden rounded-lg"
            >
                <span
                    ref="hiddenSpan"
                    class="pointer-events-none invisible absolute bottom-0 right-0 touch-none whitespace-pre"
                />
                <ComboboxInput
                    id="countryCodeComboboxInput"
                    ref="comboInput"
                    :default-value="displayTextForCountryCode(countryCode)"
                    :display-value="displayTextForCountryCode"
                    class="pointer-events-none w-10 min-w-10 touch-none outline-none"
                    data-1p-ignore
                    autocomplete="off"
                    @focus="
                        () => {
                            ;(comboInput as any).$el.select()
                        }
                    "
                    @blur="
                        () => {
                            widthToHiddenSpan(
                                displayTextForCountryCode(countryCode)
                            )
                        }
                    "
                    @change="query = $event.target.value"
                />
            </ComboboxButton>
            <ComboboxOptions
                :class="[
                    `z-menu max-h-64 whitespace-nowrap rounded-lg border border-neutral-200 bg-neutral-50 shadow-menu`,
                    `flex w-56 flex-col overflow-hidden`,
                ]"
            >
                <div
                    v-if="list.length === 0"
                    :key="`no-results-${query}`"
                    class="w-full whitespace-normal break-words p-2 text-left text-neutral-1000/20"
                >
                    <!-- Check this later Jeslopov -->
                    Nothing here
                </div>
                <div v-bind="containerProps" class="no-scrollbar max-h-64">
                    <div class="" v-bind="wrapperProps">
                        <ComboboxOption
                            v-for="{ index, data } in list"
                            :key="`${data.code}-${index}`"
                            v-slot="{ active }"
                            :value="data"
                            class="h-10 cursor-pointer"
                            as="template"
                        >
                            <li
                                :class="[
                                    `flex flex-row items-center gap-2 overflow-hidden px-3 py-2`,
                                    active ? `bg-neutral-100` : ``,
                                ]"
                            >
                                <div>
                                    {{ data.flag || `🏁` }}
                                </div>
                                <div class="truncate">
                                    {{ data.name }}
                                </div>
                                <div class="flex-none text-neutral-1000/50">
                                    {{ data.dial_code }}
                                </div>
                            </li>
                        </ComboboxOption>
                    </div>
                </div>
            </ComboboxOptions>
        </AFloat>
    </Combobox>
</template>

<script setup lang="ts">
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue'
import { useVirtualList } from '@vueuse/core'

import phoneCodes from '~/utils/phoneCodes.json'

import { ICountryCode } from './authenticationProvider'

function displayTextForCountryCode(countryCode: unknown) {
    const cc = countryCode as ICountryCode
    return `${cc.flag} ${cc.dial_code} `
}

const query = ref(``)
const sortedPhoneCodes = computed(() => {
    return phoneCodes
        .sort((a, b) => {
            return a.name.localeCompare(b.name)
        })
        .filter((code) => {
            const queryValue = query.value.toLowerCase()
            return (
                code.name.toLowerCase().includes(queryValue) ||
                code.code.toLowerCase().includes(queryValue) ||
                code.dial_code.includes(queryValue)
            )
        })
})

const { list, containerProps, wrapperProps } = useVirtualList(
    sortedPhoneCodes,
    {
        itemHeight: (i) => 40,
        overscan: 10,
    }
)

const comboInput = ref<HTMLInputElement>()

const countryCode = defineModel<ICountryCode>()

const hiddenSpan = ref<HTMLSpanElement>()

function widthToHiddenSpan(val: string) {
    hiddenSpan.value!.textContent = val
    const hiddenSpanWidth = hiddenSpan.value!.offsetWidth
    ;(comboInput.value as any).$el.style.width = `${hiddenSpanWidth}px`
}
watch(countryCode, (val) => {
    widthToHiddenSpan(displayTextForCountryCode(val))
})

onMounted(() => {
    widthToHiddenSpan(displayTextForCountryCode(countryCode.value))
})

watch(query, (newVal) => {
    widthToHiddenSpan(newVal)
})
</script>
