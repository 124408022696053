import { provideLocal } from '@vueuse/core'
import { InjectionKey } from 'vue'

type methods = 'phone' | 'email'
export enum AuthenticationStep {
    Information = 0,
    Verification = 1,
}
export interface ICountryCode {
    name: string
    flag: string
    code: string
    dial_code: string
}

export const initialCountryCode: ICountryCode = {
    name: 'United States',
    flag: '🇺🇸',
    code: 'US',
    dial_code: '+1',
}

export const authenticationInjectionKey = Symbol() as InjectionKey<{
    method: Ref<methods>
    email: Ref<string>
    phoneNumberWithoutCountryCode: Ref<string>
    countryCode: Ref<ICountryCode>
    step: Ref<AuthenticationStep>
    uiContext: AuthenticationUIContext
}>

export function formatPhoneNumber(
    phoneNumberWithoutCountryCode: string,
    countryCode: ICountryCode
): string {
    return `${countryCode.dial_code}${phoneNumberWithoutCountryCode}`
}

export type AuthenticationUIContext =
    | 'trip_creation_flow'
    | 'authentication_modal'
    | 'auth_page'

export const setupAuthenticationProvider = (
    uiContext: AuthenticationUIContext
) => {
    const method = ref<'phone' | 'email'>('email')
    const phoneNumberWithoutCountryCode = ref('')
    const countryCode = ref<ICountryCode>(initialCountryCode)
    const email = ref('')
    const step = ref(AuthenticationStep.Information)

    provideLocal(authenticationInjectionKey, {
        method,
        phoneNumberWithoutCountryCode,
        countryCode,
        email,
        step,
        uiContext,
    })
}
